
















import { defineComponent, ref, nextTick } from "@vue/composition-api"

export default defineComponent({
  name: "JeopardAiTextArea",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  emits: {
    input: (data: string) => typeof data === "string"
  },
  setup(_, { emit }) {
    const active = ref(false)
    const textarea = ref()

    function focus() {
      active.value = true
      nextTick(() => textarea.value?.focus())
    }

    function submit(e) {
      const value = e.target.value
      if (active.value !== true) return
      active.value = false
      emit("input", value)
    }

    return { focus, submit, active, textarea }
  }
})
