var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-categories-form" },
    [
      _c("ResizableText", {
        staticClass: "jeopardai-categories-form__text",
        attrs: { message: _vm.instructions },
      }),
      _vm.isViewerHostLike && _vm.viewerTeam
        ? _c("p", { staticClass: "jeopardai-categories-form__hint" }, [
            _vm._v(
              " You can submit categories for " +
                _vm._s(_vm.viewerTeam.name) +
                " "
            ),
          ])
        : _vm._e(),
      !_vm.isViewerPresenter && _vm.scribe == null
        ? _c("TextField", {
            staticClass: "jeopardai-categories-form__input",
            attrs: {
              transition: false,
              size: "sm",
              placeholder: "Type here ...",
              show: "show",
              status: "active",
              allowFreeSubmit: "",
              buttonText: "Submit",
              disabled: _vm.completed,
              maxTextLength: 100,
            },
            on: { onSubmit: _vm.submit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }