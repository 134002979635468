





























import { defineComponent } from "@vue/composition-api"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import useJeopardAiCategories from "../use/useJeopardAiCategories"
export default defineComponent({
  name: "JeopardAiCategoriesForm",
  components: { ResizableText, TextField },
  setup() {
    const {
      submit,
      completed,
      viewerTeam,
      instructions,
      isViewerHostLike,
      isViewerPresenter,
      scribe
    } = useJeopardAiCategories()

    return {
      scribe,
      submit,
      completed,
      viewerTeam,
      instructions,
      isViewerHostLike,
      isViewerPresenter
    }
  }
})
