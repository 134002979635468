var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "textarea",
        {
          ref: "textarea",
          staticClass: "jeopardai-text-area jeopardai-text-area--active",
          attrs: { type: "area" },
          on: {
            blur: _vm.submit,
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.value) + " ")]
      )
    : _c(
        "div",
        {
          staticClass: "jeopardai-text-area",
          on: {
            click: function ($event) {
              return _vm.focus()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.value) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }