


























































import { defineComponent } from "@vue/composition-api"
import useJeopardAiCategories from "../use/useJeopardAiCategories"
import JeopardAiTextArea from "./JeopardAiTextArea.vue"

export default defineComponent({
  name: "JeopardAiCategoriesList",
  components: { JeopardAiTextArea },
  setup() {
    const { grouped, isViewerHostLike, updateEntry, reset } =
      useJeopardAiCategories()

    return {
      reset,
      grouped,
      updateEntry,
      isViewerHostLike
    }
  }
})
