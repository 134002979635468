var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-categories-list" },
    _vm._l(_vm.grouped, function (value, key) {
      var _obj
      return _c(
        "div",
        { key: key, staticClass: "jeopardai-categories-list__group" },
        [
          _vm.isViewerHostLike
            ? _c("i", {
                staticClass:
                  "fas fa-trash jeopardai-categories-list__group-reset mr-2",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    _vm.isViewerHostLike && _vm.reset(value[0].teamId, key)
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "jeopardai-categories-list__team",
              class:
                ((_obj = {}),
                (_obj["jeopardai-categories-list__team--small"] =
                  key.length > 15),
                _obj),
            },
            [_vm._v(" " + _vm._s(key) + " ")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "flex-end",
                "align-self": "stretch",
                flex: "1",
              },
            },
            [
              _vm.isViewerHostLike
                ? _vm._l(value, function (item) {
                    return _c("JeopardAiTextArea", {
                      key: item.id,
                      staticClass: "jeopardai-categories-list__category mt-1",
                      class: item.candidate
                        ? "jeopardai-categories-list__category--candidate"
                        : "",
                      attrs: { value: item.value },
                      on: {
                        input: function (value) {
                          return _vm.updateEntry(item, value)
                        },
                      },
                    })
                  })
                : _vm._l(value, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticClass:
                          "jeopardai-categories-list__category jeopardai-categories-list__category--candidate mt-1",
                      },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
            ],
            2
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }