import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from "@vue/composition-api"
import {
  child,
  onValue,
  push,
  serverTimestamp,
  update,
  set
} from "firebase/database"
import type { Unsubscribe } from "firebase/database"
import { uniqBy, chain } from "lodash"
import shuffle from "knuth-shuffle-seeded"

import Team from "@shared/Team"

import useJeopardAiRound from "./useJeopardAiRound"
import useJeopardAiRootRef from "./useJeopardAiRootRef"
import useViewer from "./useViewer"
import useTeams from "@/use/useTeams"
import useIsViewerHostLike from "./useIsViewerHostLike"
import useIsViewerPresenter from "./useIsViewerPresenter"
import useStore from "@/use/useStore"

import useConfirm from "@/use/useConfirm"
import { Entry } from "../types"
import User from "@shared/User"

type EntryWithCandidate = Entry<string> & { candidate: boolean }

const N_OF_CATEGORIES = 6

function capitalize(sentence: string) {
  let words = sentence.toLowerCase().split(" ")

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1)
  }

  return words.join(" ")
}

function diversify(items: Entry<string>[], n: number): Entry<string>[] {
  const teams: string[] = uniqBy(items, entry => entry.teamId).map(
    entry => entry.teamId
  )
  const output: Entry<string>[] = []
  while (items.length > 0) {
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i]
      const index = items.findIndex(item => item.teamId === team)
      if (index === -1) continue
      const item = items[index]
      output.push(item)
      items.splice(index, 1)
    }
  }
  if (output.length > n) output.length = n
  return output
}

export default function useJeopardAiCategories() {
  const { round } = useJeopardAiRound()
  const { ref: rootRef } = useJeopardAiRootRef(round)
  const { viewer } = useViewer()
  const { teams } = useTeams()
  const { isViewerHostLike } = useIsViewerHostLike()
  const { isViewerPresenter } = useIsViewerPresenter()
  const { confirm } = useConfirm()
  const { store } = useStore()

  const enabled = ref(false)

  const viewerTeamId = computed(() =>
    isViewerHostLike.value
      ? store.getters["group/globalTeamID"]
      : viewer.value?.teamID
  )

  const viewerTeam = computed(() => teams.value?.[viewerTeamId.value])

  const max = computed(() => {
    const n = Object.keys(teams.value ?? {}).filter(
      team => !Team.isSpecialPurpose(team)
    ).length
    if (n === 0) return 0
    return Math.ceil(N_OF_CATEGORIES / n)
  })

  const entriesRef = computed(() => {
    if (enabled.value === false) return undefined
    if (rootRef.value == null) return undefined
    return child(rootRef.value, "categories")
  })
  const entries = ref<Entry<string>[]>([])

  const deduped = computed(() => {
    const sorted = entries.value.sort((a, b) => a.timestamp - b.timestamp)
    const normalized = sorted.map(entry => ({
      ...entry,
      value: entry.value.toLowerCase().replace(/\s+/g, " ").trim()
    }))
    const filtered = normalized.filter(
      ({ value, teamId }) => value !== "" && teamId != null
    )
    return uniqBy(filtered, entry => entry.value)
  })

  const items = computed(() => {
    const values = [...deduped.value]

    const seed = values[0]?.timestamp ?? ""

    const shuffled = shuffle(values, seed)

    const diversified = diversify(shuffled, N_OF_CATEGORIES)

    return chain(deduped.value)
      .map(entry => ({
        ...entry,
        candidate: diversified.some(item => item.id === entry.id),
        value: capitalize(entry.value),
        team: teams.value[entry.teamId]?.name ?? "No Team"
      }))
      .sortBy(entry => !entry.candidate)
      .sortBy(entry => entry.team)
      .value()
  })

  const grouped = computed(() =>
    items.value.reduce((acc, val) => {
      if (acc[val.team] == null) acc[val.team] = []
      acc[val.team].push(val)
      return acc
    }, {} as Record<string, EntryWithCandidate[]>)
  )

  const count = computed(
    () =>
      deduped.value.filter(entry => entry.teamId === viewer.value?.teamID)
        ?.length ?? 0
  )

  const completed = computed(() => count.value >= max.value)

  const scribes = computed(() => store.state.JeopardAi.scribes ?? [])
  const scribe = computed(() =>
    scribes.value.find(
      user =>
        user.teamID === viewer.value?.teamID && user.id !== viewer.value?.id
    )
  )

  const instructions = computed<string>(() => {
    if (isViewerHostLike.value || isViewerPresenter.value) {
      if (isViewerPresenter.value) {
        return `Enter categories`
      }

      if (items.value.length < N_OF_CATEGORIES) {
        return `Users have entered ${items.value.length}/${N_OF_CATEGORIES} categories`
      }

      return `You are good to go!`
    }

    if (scribe.value != null)
      return `${User.getShortenedName(scribe.value)} is entering categories`

    if (count.value === 0) return `Enter ${max.value} categories`

    if (count.value >= max.value) return `Thank you`

    const n = max.value - count.value

    if (n === 1) return `You can enter one category`

    return `You can enter ${n} more categories`
  })

  const updateEntry = function (item: Entry<string>, value: string) {
    set(child(entriesRef.value, `${item.id}/value`), value.trim())
  }

  function submit(value: string) {
    push(entriesRef.value, {
      value,
      userId: viewer.value?.id,
      teamId: viewerTeamId.value,
      timestamp: serverTimestamp()
    })
  }

  async function reset(teamId: string, name: string) {
    const confirmed = await confirm({
      message: `Would you like to delete ${name} category entries?`,
      title: "System"
    })

    if (confirmed === false) return

    update(
      entriesRef.value,
      entries.value.reduce((acc, val) => {
        if (val.teamId !== teamId) return acc
        acc[val.id] = null
        return acc
      }, {})
    )
  }

  let unsubscribe: Unsubscribe

  watch(entriesRef, value => {
    if (unsubscribe != null) unsubscribe()
    if (value == null) return

    unsubscribe = onValue(value, snapshot => {
      const value = <Record<string, Entry<string>>>snapshot.val() ?? {}
      entries.value = Object.entries(value).map(([key, value]) => ({
        id: key,
        value: value.value,
        userId: value.userId,
        teamId: value.teamId,
        timestamp: value.timestamp
      }))
    })
  })

  onMounted(() => {
    enabled.value = true
  })

  onBeforeUnmount(() => {
    enabled.value = false
  })

  return {
    scribe,
    viewerTeam,
    items,
    completed,
    instructions,
    reset,
    submit,
    updateEntry,
    entries,
    grouped,
    isViewerHostLike,
    isViewerPresenter
  }
}
